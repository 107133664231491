
import Vue from "vue";
import { capitalize, get } from "lodash";
import { stringifyAddress } from "@/helpers/stringifyAddress";
import { IAddress } from "@/types";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
import { hasValidAddress } from "@/helpers";
export default Vue.extend({
  name: "columnRiskAddressToolTip",
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    processStringifiedAddress(riskAddress: IAddress): string {
      return stringifyAddress(riskAddress);
    },
    processCapitalized(word: string): string {
      return capitalize(word);
    },
    processName() {
      const arg = get(this.row, "quoteApplication", {});
      if (arg && Object.keys(arg).length > 0) {
        const { firstName = "", lastName = "" } = arg?.applicant || {};
        if (firstName && lastName) {
          return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
            lastName
          )}`;
        }
      }
      return "N/A";
    }
  },
  computed: {
    addressIsValid(): boolean {
      return hasValidAddress(this.row.riskAddress);
    }
  }
});
