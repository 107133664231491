var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":true,"topActionButtonPrimary":{
    text: 'Create New Quote',
    key: 'create'
  },"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('h1',{staticClass:"pb-4 text-lg"},[_vm._v(" "+_vm._s(_vm.quoteType)+" ")]),(!_vm.showQuoteChart)?_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.makingApiRequest || _vm.loadingText),expression:"loading || makingApiRequest || loadingText"}],key:_vm.key,attrs:{"hideAllFilters":false,"tableRowActions":_vm.tableRowActionsData,"element-loading-text":_vm.loadingText,"data":_vm.quotes,"tableActions":_vm.tableActions,"addAdvancedFilter":false,"tableColumns":_vm.showLegacy ? _vm.legacyTableColumns : _vm.tableColumns,"hideToggleIcons":true,"showSelectable":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.links,"bulkMenuActions":_vm.bulkMenuActionsData,"customBulkMenuActions":_vm.userRole === 'admin' ||
      _vm.userRole === 'underwriting' ||
      _vm.userRole === 'dataEntry'
        ? _vm.customBulkMenuActions
        : [],"customBulkActionsMenuTitle":_vm.customBulkActionsMenuTitle,"filters":_vm.filters,"rowKey":"_id","reserveSelection":_vm.reserveSelection,"targetedSearch":true,"searchColumnName":"All","targetedSearchData":[
      { key: 'All', value: '' },
      { key: 'Agency Id', value: 'agentCode' },
      { key: 'Agent', value: 'firstName,lastName' },
      {
        key: 'Client Name',
        value: 'applicant.firstName,applicant.lastName'
      },
      { key: 'County', value: 'riskAddress.county' },
      { key: 'Status', value: 'status' }
    ]},on:{"selection-change":function($event){_vm.selectedDocuments = $event},"bulk-action-event":_vm.bulkActionHandler,"custom-bulk-action-event":_vm.customBulkActionHandler,"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction}},[_c('NoResultsFound',{attrs:{"slot":"notFound","image":_vm.quoteNotFoundImg,"message":"No Quotes Found."},slot:"notFound"})],1):(
      _vm.showQuoteChart &&
        ![
          'quote-report-generation',
          'quote-property-analytics',
          'quote-agents',
          'quote-agency',
          'quote-status',
          'quote-county',
          'quote-counter',
          'quote-daily-report-total',
          'quote-ratings-report',
          'quote-daily-report-single'
        ].includes(_vm.showQuoteChart)
    )?_c('Charts',{attrs:{"fitParent":['width'],"chart":_vm.chart,"allowFullScreen":true}}):(_vm.chartNeedsListView)?_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.makingApiRequest || _vm.loadingText),expression:"loading || makingApiRequest || loadingText"}],staticClass:"relative",attrs:{"hideAllFilters":false,"data":[],"element-loading-text":_vm.loadingText,"addAdvancedFilter":false,"hideToggleIcons":true,"showSelectable":true,"paginationLinks":{},"filters":_vm.quoteChartFilters,"alwaysShowTableOptions":false,"alwaysShowColumnsSection":false,"showPagination":false,"tableColumns":{},"hideSearchBar":true},on:{"fetch-query":_vm.quoteChartFetchHandler,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction}},[(_vm.showCountyFilter)?_c('div',{staticClass:"absolute right-10",staticStyle:{"top":"-0.5rem"}},[_c('SelectSearchGbsHelps',{on:{"getSelectedOption":_vm.changeValue}})],1):_vm._e(),_c('Charts',{attrs:{"filter":_vm.queryForChart,"fitParent":['width'],"chart":_vm.chart,"allowFullScreen":true}})],1):_vm._e(),_c('ModalMessage',{attrs:{"name":"quotesDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Quote Deletion","description":"This will delete the quote. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneQuote()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('quotesDeleteModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }